import React from "react"
import { Modal, ModalBody } from "reactstrap"
import PropTypes from "prop-types"

const ModalSurveillanceEnseignant = ({
  toggle,
  modal,
  row,
  detailExam,
  toggleClose,
  semestre
}) => {
  return (
    <Modal
      isOpen={modal}
      toggle={toggleClose}
      style={{
        maxWidth: "1000px",
        width: "100%",
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      }}
      fade={false}
    >
      <div
        className="modal-header"
        style={{
          width: "100% !important",
          justifyContent: "center !important",
          margin: "0 auto",
          fontSize: "17px",
          display: "flex",
        }}
        toggle={toggleClose}
      >
        <p
          style={{
            fontWeight: "bold",
            paddingRight: "5px",
            color: "#556ee6",
          }}
        >
          Surveillance par enseignant
        </p>
        {/*<i*/}
        {/*  onClick={""}*/}
        {/*  style={{ color: "#761C19", paddingRight: "5px", cursor: "pointer" }}*/}
        {/*  className="fas fa-print"*/}
        {/*></i>*/}
      </div>
      <div
        className="mt-4"
        style={{
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <div style={{ display: "flex" }}>
          <p style={{ fontWeight: "bold" }}>Enseignant:</p>
          <p style={{ paddingLeft: "5px", color: "#556ee6" }}>{row.name}</p>
        </div>
        <div style={{ display: "flex" }}>
          <p style={{ fontWeight: "bold" }}>Session:</p>
          <p style={{ paddingLeft: "5px", color: "#556ee6" }}>{semestre == 3 ?("Contrôle"):("Principale")}</p>
        </div>
          {semestre != 3 ?(
              <div style={{ display: "flex" }}>
                  <p style={{ fontWeight: "bold" }}>Semestre:</p>
                  <p style={{ paddingLeft: "5px", color: "#556ee6" }}>{semestre}</p>
              </div>
          ):(null)}

      </div>
      <ModalBody>
        <table className="table table-bordered mt-4">
          <thead>
            <tr>
              <th
                style={{
                  textAlign: "center",
                  border: "2px solid",
                  backgroundColor: "#95b3d7",
                }}
              >
                Journée
              </th>
              <th
                style={{
                  textAlign: "center",
                  border: "2px solid",
                  backgroundColor: "#95b3d7",
                }}
              >
                Date
              </th>
              <th
                style={{
                  textAlign: "center",
                  border: "2px solid",
                  backgroundColor: "#95b3d7",
                }}
              >
                Horaire
              </th>
                <th
                style={{
                  textAlign: "center",
                  border: "2px solid",
                  backgroundColor: "#95b3d7",
                }}
              >
                Salle
              </th>
            </tr>
          </thead>
          {detailExam.map((el, index) => (
            <tbody key={index}>
              <tr>
                <td
                  style={{
                    textAlign: "center",
                    border: "2px solid",
                    verticalAlign: "middle",
                  }}
                  rowSpan={el.detail.length + 1}
                >
                  {el.jour}
                </td>
                <td
                  style={{
                    textAlign: "center",
                    border: "2px solid",
                    verticalAlign: "middle",
                  }}
                  rowSpan={el.detail.length + 1}
                >
                  {el.date}
                </td>
              </tr>
              {el.detail.map((elem, indice) => (
                <tr key={indice}>
                  <td style={{ textAlign: "center", border: "2px solid" }}>
                    {elem.quart}
                  </td>
                    <td style={{ textAlign: "center", border: "2px solid" }}>
                    {elem.salle}
                  </td>
                </tr>
              ))}
            </tbody>
          ))}
        </table>
      </ModalBody>
    </Modal>
  )
}

export default ModalSurveillanceEnseignant
ModalSurveillanceEnseignant.propTypes = {
  modal: PropTypes.object,
  toggle: PropTypes.object,
  row: PropTypes.object,
  detailExam: PropTypes.object,
  toggleClose: PropTypes.object,
  semestre: PropTypes.object,
}
